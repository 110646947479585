export const PCAs = [
    {
        "name": "Garrett French",
        "email": "cgf3@rice.edu",
        "year": "Senior",
        "areaOfStudy": "Mechanical Engineering"   
    },
    {
        "name": "Christina Ko",
        "email": "cyk3@rice.edu",
        "year": "Sophomore",
        "areaOfStudy": "Neuroscience"
    },
]
